import { del, get, patch, post } from "./fetcher";

export async function getProducts(queryString) {
  return await get(`products${queryString}`);
}
export async function getProductById(id) {
  return await get(`products/${id}`);
}
export async function createProducts(product) {
  return await post(`products`, product);
}
export async function deleteProductById(id) {
  return await del(`products/${id}`);
}
export async function updateProducts(id, product) {
  return await patch(`products/${id}`, product);
}
