import { useEffect, useState } from "react";
import { getProducts } from "../../apiIntegrations/products";
import { Button } from "antd";
import { Drawer, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { del, patch } from "../../apiIntegrations/fetcher";
export default function Products() {
  const [products, setProducts] = useState({ data: [] });
  const [product, setProduct] = useState({});
  const [name, setName] = useState("");
  const [rent, setRent] = useState(0);
  const [buy, setBuy] = useState(0);
  const [description, setDescription] = useState("");
  useEffect(() => {
    loadProducts();
  }, []);
  async function loadProducts() {
    setProducts(await getProducts("?limit=1000"));
  }
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ flex: 1, overflow: "scroll" }}>
      Products
      <div style={{ padding: 20 }}>
        {products.data.map((p, i) => (
          <div
            style={{
              margin: 5,
              display: "flex",
              borderBottom: "1px solid #ddd",
            }}
          >
            <div style={{ padding: 20 }}>{i + 1}</div>
            <div
              style={{
                height: 80,
                width: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ccc",
              }}
            >
              <img src={p.images[0]} height="80px" />
            </div>
            <div style={{ paddingLeft: 20, marginRight: 20 }}>
              <div>Name: {p.name}</div>
              <div>Ammount: {p.rent}</div>
              <div>stock: {p.stock}</div>
            </div>
            <div>
              <Button
                onClick={(e) => {
                  setName(p.name);
                  setRent(p.rent);
                  setBuy(p.buy);
                  setDescription(p.description);
                  setProduct(p);
                  showDrawer();
                }}
              >
                EDIT
              </Button>
              <Button
                onClick={async (e) => {
                  await del("products/" + p._id);
                  await loadProducts();
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onClose}
        open={open}
        // style={{ width: 600 }}
        size="large"
      >
        <div style={{}}>
          {/* {JSON.stringify(product)} */}
          <div style={{ padding: 10 }}>
            Name:{" "}
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div style={{ padding: 10 }}>
            Rent:
            <Input value={rent} onChange={(e) => setRent(e.target.value)} />
          </div>
          <div style={{ padding: 10 }}>
            Price:
            <Input value={buy} onChange={(e) => setBuy(e.target.value)} />
          </div>
          <div style={{ padding: 10 }}>
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div style={{ padding: 10 }}>
            <Button
              onClick={async (e) => {
                await patch("products/" + product._id, {
                  name,
                  rent,
                  buy,
                  description,
                });
                await loadProducts();
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
