// Login signup api calls
import { get, post, put, del } from "./fetcher";
const headers = {
  "Content-Type": "application/json",
};

export async function signup(payload) {
  try {
    const response = await post("signup", payload, headers);
    console.log(response);
    return response
  } catch (error) {
    console.log(error);
    return {error:true ,message:error}
  }
}

export async function login(payload) {
  try {
    const response = await post("login", payload, headers);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function logout() {
  const token = localStorage.getItem("Authorization");
  try {
    const response = await post("logout", {}, { ...headers, Cookie: token });
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}
export async function sports() {
  alert("hello")
  // const token = localStorage.getItem("Authorization");
  // try {
  //   const response = await post("logout", {}, { ...headers, Cookie: token });
  //   console.log(response);
  // } catch (error) {
  //   console.error(error);
  // }
}

