import sprentzo from "./logo1.png";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { login } from "../../apiIntegrations/auth";
import "./index.css";
const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("Authorization");
    console.log(token);
    if (token) {
      navigate("/products");
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          position: "absolute",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          background: "#fff",
          flexDirection: "column",
          maxWidth: 500,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            paddingTop: 40,
          }}
        >
          <img src={sprentzo} alt="sprentzoimg" height={80} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 100,
          }}
        >
          <div
            style={{
              border: "0px solid red",
              width: "90%",
              padding: 10,
              background: "#fff",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                name="userId"
                className="login-input"
                value={email}
                placeholder="User ID"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                name="password"
                value={password}
                placeholder="Password"
                onChange={(event) => setPassword(event.target.value)}
                type="password"
                className="login-input"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={async () => {
                  let res = await login({ userId: email, password });
                  console.log(res.token);
                  if (res.error) {
                    alert("Login Failed Please check the Credentials");
                    console.log(res);
                  } else {
                    localStorage.setItem("Authorization", res.token);
                    navigate("/products");
                  }
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                  margin: 10,
                  padding: 8,
                  borderRadius: 5,
                  color: "#fff",
                  background: "#7868ab",
                }}
              >
                <span
                  style={{
                    fontSize: 23,
                    fontWeight: 500,
                    // color: "rgb(225 84 86)",
                  }}
                >
                  Login
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div style={{ height: 200, textAlign: "center" }}>
          <div>Create new Account</div>
          <span
            style={{
              fontSize: 23,
              fontWeight: 500,
              color: "#555",
              color: "rgb(225 84 86)",
            }}
            onClick={(e) => {
              navigate("/signup");
            }}
          >
            SignUp
          </span>
        </div> */}
      </div>
    </div>
  );
};
export default Login;
