import axios from "axios";

export const apiEndpoint = "https://api.sprentzo.com/";
// export const apiEndpoint = "http://localhost:5001/";

const axiosInstance = axios.create({
  baseURL: apiEndpoint,
});

const get = async (path, params = {}, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.get(path, {
      params: params,
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const post = async (path, data = null, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.post(path, data, {
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const put = async (path, data = null, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.put(path, data, {
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
const patch = async (path, data = null, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.patch(path, data, {
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const del = async (path, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.delete(path, {
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export { get, post, put, patch, del };
