import { Outlet } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import MainSidebar from "../components/MainSidebar";
import { useEffect } from "react";
import { get } from "../apiIntegrations/fetcher";
import { useNavigate } from "react-router-dom";

export default function Layout() {
  const navigate = useNavigate();

  useEffect(() => {
    checkForUserSession();
  }, []);
  async function checkForUserSession() {
    const token = localStorage.getItem("Authorization");
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDVmM2JlZjVlMDhjMjA2MWU1ZmU2OTgiLCJpYXQiOjE2ODQyNDQ4OTZ9.N8gkmWdsVjsF5uaXQyDFbTu5wQv9W9SqRWjXxALSiZM"
    try {
      const data = await get("/profile", {}, { Authorization: token });
      console.log(data);
      localStorage.setItem("userProfile", JSON.stringify(data));
    } catch (e) {
      console.log(e);
      navigate("/login");
    }
  }
  return (
    <div
      style={{
        // border: "1px solid red",
        display: "flex",
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ width: 220, background: "#f1f1f1" }}>
        <MainSidebar />
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div style={{ height: 60, background: "#999" }}>
          {/* <MainHeader /> */}
        </div>
        <Outlet />
      </div>
    </div>
  );
}
