import { Routes, Route, Outlet, Link } from "react-router-dom";
export default function MainSidebar() {
  return (
    <div>
      <div>Sprentzo</div>
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: 12, padding: 10 }}>Apps</div>
        {[
          {
            title: "dashboard",
            path: "/dashboard",
          },
          {
            title: "products",
            path: "/products",
          },
        ].map((link, i) => (
          <Link to={link.path}>
            <div
              key={"link" + i}
              style={{
                fontWeight: 600,
                padding: 10,
                textTransform: "capitalize",
                cursor: "pointer",
                borderBottom: "1px solid #ccc",
              }}
            >
              {link.title}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
